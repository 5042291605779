    const timer = document.getElementById('js-timer');
    if (timer) {
        let end = timer.dataset.end;

        // Set the date we're counting down to
        let countDownDate = new Date(end).getTime();

        // Update the count down every 1 second
        let x = setInterval(function () {
            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
                (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            hours = hours < 10 ? '0' + hours : hours;
            minutes = minutes < 10 ? '0' + minutes : minutes;
            seconds = seconds < 10 ? '0' + seconds : seconds;

            // Display the result in the element with id="timer"
            timer.innerHTML =
                "<span class='header-bar__list-text'>" +
                days +
                "</span> dni <span class='header-bar__list-text'>" +
                hours +
                ':' +
                minutes +
                ':' +
                seconds +
                '</span>';

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                timer.innerHTML =
                    "<span class='header-bar__list-text'>Zakończono</span>";
            }
        }, 1000);
    }